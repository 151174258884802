import config from '@shared/config';

const BASE_PUBLISHER_URL = `${config.publisherApiUrl}/api/v1/publisher`;
const BASE_AUTH_URL = `${config.publisherApiUrl}/api/v1/publisher/auth`;
const BASE_MARKETPLACE_URL = `${config.publisherApiUrl}/api/v1/publisher/marketplaces`;
const BASE_REFUND_REQUESTS_URL = `${config.publisherApiUrl}/api/v1/publisher/refund-requests`;
const BASE_SALES_REQUESTS_URL = `${config.publisherApiUrl}/api/v1/publisher`;
const BASE_PROFILE_URL = `${config.publisherApiUrl}/api/v1/publisher/profile`;
const BASE_REGISTRY_URL = `${config.publisherApiUrl}/api/v1/publisher/registries/account`;
const BASE_KYC_URL = `${config.publisherApiUrl}/api/v1/publisher/kyc/proof-of-identity-business-document`;

export const publisherAuthApiRoutes = {
  publisherLogin: `${BASE_AUTH_URL}/email/login`,
  publisherLoginOtp: `${BASE_AUTH_URL}/email/login_otp`,
  publisherRegister: `${BASE_AUTH_URL}/email/register`,
  publisherRegisterConfirmEmail: `${BASE_AUTH_URL}/email/confirm`,
  publisherForgot: `${BASE_AUTH_URL}/forgot`,
  publisherResetPassword: `${BASE_AUTH_URL}/reset/password`,
  publisherRegisterRequestOtpCode: `${BASE_AUTH_URL}/email/request_confirm_email`,
  publisherMe: `${BASE_AUTH_URL}/me`,
  publisherChangePassword: `${BASE_AUTH_URL}/change_password`,
};

export const publisherProfileApiRoutes = {
  updatePublisherProfile: BASE_PROFILE_URL,
  getPublisherProfile: BASE_PROFILE_URL,
};

export const publisherRegistryApiRoutes = {
  getHarborAccount: BASE_REGISTRY_URL,
  generateHarborAccount: `${BASE_REGISTRY_URL}/generate`,
};

export const publisherMarketplaceApiRoutes = {
  getPublisherProducts: `${BASE_MARKETPLACE_URL}/products`,
  getPublisherSubmittedProducts: `${BASE_MARKETPLACE_URL}/submissions`,
  getPublisherProductTypes: `${BASE_PUBLISHER_URL}/product-types`,
  getPublisherCategories: (productTypeId: number) =>
    `${BASE_PUBLISHER_URL}/categories/${productTypeId}`,
  getPublisherTags: `${BASE_PUBLISHER_URL}/tags`,
  changeProductVisibility: (productId: number) =>
    `${BASE_MARKETPLACE_URL}/products/${productId}/change-visibility`,
  deleteDraftProductSubmission: (productId: number) =>
    `${BASE_MARKETPLACE_URL}/submissions/${productId}`,
  createProduct: `${BASE_MARKETPLACE_URL}/products`,
  saveProductSubmission: `${BASE_MARKETPLACE_URL}/submissions`,
  submitProductToMarketplace: (submissionId: number) =>
    `${BASE_MARKETPLACE_URL}/submissions/${submissionId}/submit`,
  changeProductSubmissionVersion: `${BASE_MARKETPLACE_URL}/submissions`,
  cloneSubmission: `${BASE_MARKETPLACE_URL}/submissions/clone`,
  reviewOverview: (productId: string) =>
    `${BASE_MARKETPLACE_URL}/products/${productId}/reviews/avg-point`,
  getPreDeployModels: `${BASE_PUBLISHER_URL}/pre-deployed-models`,
  getSelectedModelDetail: (modelSelectedId: string) =>
    `${BASE_PUBLISHER_URL}/pre-deployed-models/${modelSelectedId}`,
  choosePreDeployModel: () =>
    `${BASE_PUBLISHER_URL}/pre-deployed-models/choose`,
};

export const publisherRefundRequestsApiRoutes = {
  getPublisherRefundRequests: BASE_REFUND_REQUESTS_URL,
  approvePublisherRefundRequest: (id: number) =>
    `${BASE_REFUND_REQUESTS_URL}/${id}/approve`,
  rejectPublisherRefundRequest: (id: number) =>
    `${BASE_REFUND_REQUESTS_URL}/${id}/reject`,
  getPublisherRefundHistories: (id: number) =>
    `${BASE_REFUND_REQUESTS_URL}/${id}/histories`,
};

export const publisherSalesApiRoutes = {
  getPublisherProductStatistics: `${BASE_SALES_REQUESTS_URL}/product-statistics`,
  getPublisherSales: `${BASE_SALES_REQUESTS_URL}/sales`,
  getPublisherWithdrawRequests: `${BASE_SALES_REQUESTS_URL}/withdraw-requests`,
  getPublisherStatistics: `${BASE_SALES_REQUESTS_URL}/statistics`,
  getPublisherBalanceStatistics: `${BASE_SALES_REQUESTS_URL}/balance-statistics`,
  getReceipt: (saleId: number) =>
    `${BASE_SALES_REQUESTS_URL}/sales/${saleId}/receipt`,
  requestWithdraw: `${BASE_SALES_REQUESTS_URL}/withdraw-requests`,
  getWithdrawByID: (id: number) =>
    `${BASE_SALES_REQUESTS_URL}/withdraw-requests/by-id/${id}`,
  withdrawCurrency: `${BASE_PUBLISHER_URL}/withdraw-requests/withdraw-currency-setting`,
};

export const publisherSettingsApiRoutes = {
  settlementSettings: `${BASE_MARKETPLACE_URL}/products/settings/settlement-settings`,
  feeSettings: `${BASE_MARKETPLACE_URL}/products/settings/fee-settings`,
};

export const publisherKycApiRoutes = {
  uploadPublisherKyc: BASE_KYC_URL,
  resetPublisherKyc: `${BASE_KYC_URL}/reset`,
};
