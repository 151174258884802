import { useQuery } from '@tanstack/react-query';

import { IS_OFFLINE } from '@shared/constants';

const useIsOfflineQuery = () => {
  return useQuery({
    queryKey: [IS_OFFLINE],
  });
};
export default useIsOfflineQuery;
