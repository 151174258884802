export const MAX_PRODUCT_NAME_LENGTH = 30;

export const MAX_USERNAME_LENGTH = 30;
export const MAX_EMAIL_LENGTH = 254;
export const MAX_PASSWORD_LENGTH = 40;
export const MAX_TEXTAREA_LENGTH = 500;
export const MAX_CHAT_INPUT_LENGTH = 1000;

export const LIMIT_TOKENS = 1000000000;
export const MAX_NET_PRICE = 999999.99;
